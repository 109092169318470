


















































































import Vue from "vue";
import Search from "@/components/Search.vue";
import SubHeader from "@/views/layout/header/SubHeader.vue";
import MenuFooter from "@/views/layout/footer/MenuFooter.vue";
import TextFloating from "@/components/input/TextFloating.vue";
import FileInput from "@/components/input/FileInput.vue";
import ListImageModel from "@/models/createTicket/ListImageModel";
import { Component, Ref } from "vue-property-decorator";
@Component({
  components: {
    Search,
    SubHeader,
    MenuFooter,
    FileInput,
    TextFloating,
  },
})
export default class FormReject extends Vue {
  titlePage = "Detail Ticket";
  listImage: ListImageModel[] = [];

  @Ref() fileImg!: FileInput;

  addUploadImgRow() {
    const images = new ListImageModel();
    this.listImage.push(images);
  }
  removeUploadRow(i: number) {
    this.listImage.splice(i, 1);
  }
  chooseImg() {
    if (this.fileImg) {
      this.fileImg.onElementClick();
    }
  }
}
